<template>
  <div>
    <Modal :options="{close: true}" @close="$emit('close')">
      <div class="title">Incidencias</div>
      <div class="body">

        <div class="row mb-4">
          <div class="col-sm-12">
            <table class="lista_incidencias">
              <thead>
                <tr>
                  <th>Incidencia</th>
                  <th>Tipo incidencia</th>
                  <th>Descripción</th>
                  <th>Fecha creación</th>
                  <th>Estatus</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="incidencia in incidencias_validas" :key="incidencia.id">
                  <td>{{ obtener_nombre(incidencia.subtipo) }}</td>
                  <td>{{ obtener_tipo(incidencia.tipo) }}</td>
                  <td class="text-truncate">{{ incidencia.descripcion }}</td>
                  <td>{{ $moment(incidencia.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
                  <td>{{ obtener_estatus(incidencia.estatus) }}</td>
                  <td>
                    <img class="mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar incidencia" title="Editar incidencia" @click="editar_incidencia(incidencia)" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
      </div>
    </Modal>

    <Detalle v-if="modal_incidencias" :solicitud="solicitud" :incidencia="incidencia" :catalogo="opciones" @update="$emit('update')" @close="cancelar_editar_incidencia" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import Detalle from './Incidencia/Detalle'

  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      Modal, Detalle
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            incidencias: []
          }
        }
      }
      ,tipo_inicidencia: {
        type: Array
        ,default: ['dictamen_cliente','dictamen_inmueble','dictamen_clg']
      }
    }
    ,data() {
      return {
        opciones: []
        ,modal_incidencias: false
        ,incidencia: {
          subtipo: null
          ,descripcion: null
        }
      }
    }
    ,mounted() {
      this.obtener_catalogos();
    }
    ,methods: {
      async obtener_catalogos() {
        this.opciones = await this.$helper.cache_store('apps.formalizacion.catalogos_todos',async () => {
          let cliente,inmueble,clg,valuacion;

          cliente = (await apiCatalogo.buscar_catalogo('incidencias_cliente')).data;
          inmueble = (await apiCatalogo.buscar_catalogo('incidencias_inmueble')).data;
          clg = (await apiCatalogo.buscar_catalogo('incidencias_clg')).data;
          valuacion = (await apiCatalogo.buscar_catalogo('incidencias_valuacion')).data;

          let opciones = cliente.opciones;
          opciones = opciones.concat(inmueble.opciones);
          opciones = opciones.concat(clg.opciones);
          opciones = opciones.concat(valuacion.opciones);

          this.$log.info('opciones', opciones);

          return opciones;
        })
      }
      ,obtener_nombre(subtipo) {
        let nombre;

        this.opciones.forEach(opcion => {
          if (opcion.valor == subtipo)
            nombre = opcion.nombre;
        })

        return nombre;
      }
      ,obtener_tipo(tipo) {
        switch(tipo) {
          case 'dictamen_cliente':
            return 'Dictamen cliente';
            break;
          case 'dictamen_inmueble':
            return 'Dictamen inmueble';
            break;
          case 'dictamen_clg':
            return 'Dictamen CLG';
            break;
          case 'avaluo':
            return 'Avaluo';
        }
      }
      ,obtener_estatus(estatus) {
        switch(estatus) {
          case 0:
            return 'Cancelado';
          case 1:
            return 'Sin resolver';
          case 2:
            return 'Resuelta';
        }
      }
      ,avanzar_proceso() {
        if (this.incidencias_sin_resolver > 0)
          return;

        this.$emit('avanzar');
      }
      ,editar_incidencia(incidencia) {
        if (incidencia.estatus == 2)
          return this.$helper.showMessage('Error','No puedes editar una incidencia resuelta','error','alert');

        this.incidencia = incidencia;
        this.modal_incidencias = true;
      }
      ,cancelar_editar_incidencia() {
        this.incidencia = {
          subtipo: null
          ,descripcion: null
        }
        this.modal_incidencias = false;
      }
    }
    ,computed: {
      incidencias_validas() {
        let incidencias = []

        this.solicitud.incidencias.forEach(incidencia => {
          if (this.tipo_inicidencia.indexOf(incidencia.tipo) > -1)
            incidencias.push(incidencia)
        })

        return incidencias;
      }
      ,incidencias_sin_cerrar() {
        let incidencias = 0;

        this.incidencias_validas.forEach(incidencia => {
          if (incidencia.estatus == 1)
            incidencias++;
        })

        return incidencias;
      }
    }
    ,watch: {
      solicitud: {
        handler(solicitud) {
          let sin_resolver = 0;

          solicitud.incidencias.forEach(incidencia => {
            if (this.tipo_inicidencia.indexOf(incidencia.tipo) > -1 && incidencia.estatus == 1)
              sin_resolver++;
          })

          if (sin_resolver == 0) {
            this.$helper.showMessage('Exito!', 'Se han resuleto todas las inicidencias existentes','success','alert');
            this.$emit('close');
          }
        }
        ,deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .body {
    min-height: 200px;
  }

  .lista_incidencias {
    width: 100%;

    thead {
      tr {
        background-color: #4C4C4C;
        color: #fff;

        th {
          padding: 5px 10px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 5px 10px;

          img {
            width: 20px;
            cursor: pointer;
          }
        }
      }

      tr:nth-child(2n+0) {
        background-color: #E6E6E6;
      }
    }
  }
</style>